//import logo from './logo.svg';
import logoHeader from "./img/LOGOS01-shadow.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        */}

        <img src={logoHeader} alt="logo" className="App-logo" />
        <p>
          Espaço reservado para meus projetos pessoais! Não deixe de
          conhecer esses projetos, visite os links abaixo:
        </p>
        <div className="buttons">
          <Button
            href="https://portfolio.or.app.br/"
            //target="_blank"
            rel="noopener noreferrer"
            size="lg"
            variant="primary"
          >
            PORTFÓLIO
          </Button>
        </div>
      </header>
    </div>
  );
}

export default App;
